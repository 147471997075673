<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-card>
      <v-toolbar
        flat
        color="transparent"
      >
        <v-text-field
          v-model="options.search"
          rounded
          dense
          outlined
          hide-details
          label="搜索"
          placeholder="按属性名、显示名搜索"
          :append-icon="icons.mdiMagnify"
          class="flex-grow-0"
          @click:append="getDesserts"
        />
        <v-spacer></v-spacer>
        <v-btn
          v-permission="['60001']"
          color="primary"
          dark
          @click="createBtnClick"
        >
          添加属性
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :items-per-page.sync="options.pageSize"
        :page.sync="options.page"
        :server-items-length="totalDesserts"
        :loading="loading"
        :disable-sort="true"
        :footer-props="{itemsPerPageOptions: [10, 15, 20, 50, 100]}"
        @update:items-per-page="getDesserts"
        @update:page="getDesserts"
      >
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | dateFormat }}
        </template>
        <template v-slot:item.updated_at="{ item }">
          {{ item.updated_at | dateFormat }}
        </template>
        <template
          v-slot:item.actions="{ item }"
        >
          <v-btn
            v-permission="['60002']"
            icon
            @click="itemClick(item)"
          >
            <v-icon>{{ icons.mdiArrowRightCircle }}</v-icon>
          </v-btn>
          <v-btn
            v-permission="['60004']"
            icon
            @click="deleteBtnClick(item)"
          >
            <v-icon>{{ icons.mdiDelete }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="editDialog"
      max-width="650"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card
        :loading="editDialogLoading"
        class="pa-sm-10"
      >
        <v-card-title class="justify-center text-h5">
          {{ !rowId ? '新增属性' : viewRow ? '查看属性': '编辑属性' }}
        </v-card-title>
        <v-card-subtitle class="text-center mt-n2">
          {{ !rowId ? '创建一个新的商品属性' : viewRow ? '查看商品属性详细信息': '修改商品属性信息' }}
        </v-card-subtitle>
        <v-card-text class="mt-5">
          <v-form
            ref="optionFormRef"
            :disabled="viewRow"
            lazy-validation
          >
            <v-subheader class="justify-center">基本信息</v-subheader>
            <v-divider class="mb-2"></v-divider>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="row.option_name"
                  :counter="20"
                  :rules="[rules.required, rules.counter]"
                  label="属性名称"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="row.display_name"
                  :counter="20"
                  :rules="[rules.required, rules.counter]"
                  label="显示名称"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-subheader class="justify-center">属性值</v-subheader>
            <v-divider class="mb-2"></v-divider>
            <v-card-text
              style="max-height: 500px"
            >
              <draggable
                v-model="row.values"
                handle=".handle"
              >
                <transition-group type="transition" name="flip-list">
                  <v-row
                    v-for="(item, index) in row.values"
                    :key="item.sort_order"
                    class="align-center"
                  >
                    <v-col
                      cols="6"
                    >
                      <v-text-field
                        v-model="item.label"
                        :rules="[rules.required, rules.counter]"
                        hide-details="auto"
                        label="属性值"
                        outlined
                        dense
                        required
                        class="align-center"
                      >
                        <template v-slot:prepend>
                          <v-btn
                            icon
                            class="handle"
                          >
                            <v-icon>{{ icons.mdiSort }}</v-icon>
                          </v-btn>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="4"
                    >
                      <v-checkbox
                        v-model="item.is_default"
                        label="设为默认值"
                        @change="setDefaultValue(index)"
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      cols="2"
                    >
                      <v-btn
                        v-if="!viewRow"
                        icon
                        @click="deleteOptionValue(index)"
                      >
                        <v-icon>{{ icons.mdiDelete }}</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </transition-group>
                <template
                  v-if="!viewRow"
                  v-slot:footer
                >
                  <v-btn
                    text
                    color="primary"
                    @click="addOptionValue"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                    添加属性值
                  </v-btn>
                </template>
              </draggable>
            </v-card-text>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center mt-3">
          <v-btn
            v-if="viewRow"
            v-permission="['60003']"
            color="primary"
            dark
            @click="editClick"
          >
            编辑
          </v-btn>
          <v-btn
            v-else
            color="primary"
            dark
            @click="editDialogSubmit"
          >
            保存
          </v-btn>
          <v-btn
            outlined
            @click="editDialogCancel"
          >
            取消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          删除商品属性后会导致与该属性关联的商品变体发生变化，确定要删除吗？
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="cancelDelete"
          >
            取消
          </v-btn>
          <v-btn
            color="success"
            @click="deleteItem"
          >
            确认
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
  getOptions, addOption, updateOption, getOption, deleteOption,
} from '@/api/goods'
import {
  mdiMagnify,
  mdiClose,
  mdiDelete,
  mdiArrowRightCircle,
  mdiCalendar,
  mdiPencil,
  mdiPlus,
  mdiSort,
} from '@mdi/js'
import draggable from 'vuedraggable'

export default {
  name: 'Options',
  components: {
    draggable,
  },
  data() {
    return {
      desserts: [],
      rules: {
        required: value => !!value || '必填项.',
        counter: value => (value && value.length <= 20) || '最大20个字符',
      },
      totalDesserts: 0,
      editDialog: false,
      deleteDialog: false,
      editDialogLoading: true,
      rowId: null,
      viewRow: false,
      row: {
        option_name: '',
        display_name: '',
        values: [],
      },
      loading: true,
      headers: [
        { text: '属性名称', value: 'option_name' },
        { text: '显示名称', value: 'display_name' },
        { text: '使用商品数量', value: 'goods_count' },
        { text: '创建时间', value: 'created_at' },
        { text: '修改时间', value: 'updated_at' },
        { text: '操作', align: 'center', value: 'actions' },
      ],
      icons: {
        mdiMagnify,
        mdiClose,
        mdiDelete,
        mdiArrowRightCircle,
        mdiCalendar,
        mdiPencil,
        mdiPlus,
        mdiSort,
      },
      options: {
        page: 1,
        pageSize: 10,
        search: '',
      },
    }
  },

  created() {
    this.getDesserts()
  },

  methods: {
    getDesserts() {
      this.loading = true
      getOptions(this.options).then(response => {
        this.desserts = response.data.results
        this.totalDesserts = response.data.count
        this.loading = false
      })
    },
    async itemClick(item) {
      this.row.values = []
      this.editDialog = true
      this.editDialogLoading = true
      this.viewRow = true
      this.rowId = item.id
      const { data } = await getOption(item.id)
      this.row = data
      this.editDialogLoading = false
    },
    deleteBtnClick(item) {
      this.rowId = item.id
      this.deleteDialog = true
    },
    cancelDelete() {
      this.rowId = null
      this.deleteDialog = false
    },
    async deleteItem() {
      await deleteOption(this.rowId)
      this.rowId = null
      this.deleteDialog = false
      this.$toast.success('删除成功')
      this.options.page = 1
      this.getDesserts()
    },
    async editClick() {
      this.viewRow = false
    },
    createBtnClick() {
      if (this.$refs.optionFormRef) {
        this.$refs.optionFormRef.reset()
      } else {
        this.row.option_name = null
        this.row.display_name = null
      }
      this.row.values = []
      this.editDialogLoading = false
      this.viewRow = false
      this.rowId = null
      this.editDialog = true
    },
    async editDialogSubmit() {
      if (!this.$refs.optionFormRef.validate()) return
      if (!this.row.values.length) {
        this.$toast.error('至少需要一个属性值')
        return
      }
      if (this.rowId) {
        await updateOption(this.rowId, this.row)
        this.$toast.success('更新成功')
      } else {
        await addOption(this.row)
        this.$toast.success('创建成功')
      }
      this.getDesserts()
      this.$refs.optionFormRef.reset()
      this.editDialog = false
    },
    editDialogCancel() {
      this.$refs.optionFormRef.reset()
      this.row.values = []
      this.editDialog = false
    },
    addOptionValue() {
      this.row.values.push({
        id: null,
        label: '',
        is_default: false,
        sort_order: this.row.values.length,
      })
    },
    deleteOptionValue(index) {
      this.row.values.splice(index, 1)
    },
    setDefaultValue(index) {
      const item = this.row.values[index]
      if (item.is_default) {
        this.row.values.forEach((el, i) => {
          if (i !== index) el.is_default = false
        })
      }
    },
  },
}
</script>
<style lang="sass">
.flip-list-move
  transition: transform 0.5s

.no-move
  transition: transform 0s

</style>
